import React from 'react';
import menu from 'constants/menu';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { MeetingRoomOutlined, RocketLaunchSharp } from '@mui/icons-material';
import { useAuth } from 'context/AuthContext';

const AppSidebar = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const getUrl = searchParams.get('name')
    ? `${location.pathname}?name=${searchParams.get('name')}`
    : location.pathname;

  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  const activeMenu = (path, childPaths) => {
    if (getUrl === path) {
      return true;
    } else if (childPaths) {
      const menuItem = childPaths.find(item => item === getUrl);
      return menuItem === getUrl;
    }
    return false;
  };

  const signOut = () => {
    localStorage.removeItem('user');
    setUser(null);
    navigate('/auth/login', { replace: true });
  };
  return (
    <div className="w-full flex flex-col items-center">
      <div className="flex items-center justify-center text-5xl">
        <RocketLaunchSharp fontSize="inherit" className="text-orange-400" />
      </div>
      <div className="flex flex-col w-full mt-72">
        <div className="text-sm mb-4 font-semibold text-slate-500">Menü</div>
        {menu.map((item, index) => (
          <div key={index} className="mb-2">
            <Link
              to={item.path}
              key={index}
              className={`mb-2 flex items-center hover:bg-orange-400 hover:text-slate-50 px-3 py-2 rounded-md transition-all ${
                activeMenu(item.path, item?.childPaths)
                  ? 'bg-orange-400 text-slate-50'
                  : 'text-slate-400'
              }`}
            >
              <item.Icon fontSize="small" className={`${item.icon} mr-4`} />
              <span className="font-medium">{item.name}</span>
            </Link>
          </div>
        ))}
        <div
          onClick={signOut}
          className="text-slate-400 mb-2 flex items-center hover:bg-orange-400 hover:text-slate-50 px-3 py-2 rounded-md transition-all cursor-pointer"
        >
          <MeetingRoomOutlined className="mr-4" fontSize="small" />
          <span className="font-medium">Çıkış Yap</span>
        </div>
      </div>
      <div className="flex text-sm text-slate-500 absolute bottom-10">
        {new Date().getFullYear()} © Admin Panel
      </div>
    </div>
  );
};

export default AppSidebar;
