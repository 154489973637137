import React, { useEffect, useState } from 'react';
import { AppTable } from 'components';
import api from 'services/api';

const WebsiteContactForm = () => {
  const [data, setData] = useState([]);
  const [page] = useState(0);

  const titles = [
    'İsim',
    'E-mail',
    'Şirket İsmi',
    'Telefon Numarası',
    'Mesaj',
    'Gönderme Tarihi'
  ];

  useEffect(() => {
    const getData = async () => {
      await api.global
        .list('webContactForm', page)
        .then(async res => {
          setData(res.data);
        })
        .catch(error => console.log('error', error));
    };
    getData();
  }, []);

  return (
    <main className="w-full">
      <AppTable
        titles={titles}
        rows={data}
        isSubstringActive={false}
        type="contactForms"
        hideRows="__v,_id,updatedAt"
      />
    </main>
  );
};

export default WebsiteContactForm;
