import {
  Campaign,
  ContactPage,
  GroupsSharp,
  HandshakeSharp,
  HomeOutlined,
  LibraryBooks,
  LocalMallSharp,
  Mail,
  RestaurantMenuRounded
} from '@mui/icons-material';

export default [
  {
    name: 'Anasayfa',
    Icon: HomeOutlined,
    path: '/'
  },
  {
    name: 'Müşteriler',
    Icon: HandshakeSharp,
    path: '/customers',
    childPaths: ['/customers/add', '/customers/detail']
  },
  {
    name: 'İşletmeler',
    Icon: LocalMallSharp,
    path: '/stores',
    childPaths: ['/stores/detail']
  },
  {
    name: 'Kullanıcılar',
    Icon: GroupsSharp,
    path: '/users'
  },
  {
    name: 'Kampanyalar',
    Icon: Campaign,
    path: '/campaigns'
  },
  {
    name: 'Anket',
    Icon: LibraryBooks,
    path: '/survey'
  },
  {
    name: 'Menu',
    Icon: RestaurantMenuRounded,
    path: '/menu'
  },
  {
    name: 'İletişim Formları',
    Icon: ContactPage,
    path: '/website/contactForms'
  },
  {
    name: 'Info Mailleri',
    Icon: Mail,
    path: '/website/infoMail'
  }
];
