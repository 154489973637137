import { useRoutes } from 'react-router-dom';
import routes from './pages';
import './assets/style/main.scss';
import {
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement
} from 'chart.js';
import Chart from 'chart.js/auto';

// Chart.register(CategoryScale);
// Chart.register(ArcElement);
// Chart.register(LinearScale);
// Chart.register(PointElement);
// Chart.register(LineElement);
// Chart.register(BarElement);

function App() {
  return useRoutes(routes);
}

export default App;
