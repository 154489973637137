import React, { useEffect, useState } from 'react';
import { AppTable } from 'components';
import api from 'services/api';

const UserList = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);

  const titles = [
    'İsim',
    'Soyisim',
    'E-Mail',
    'Puan',
    'Anket Cevap Sayısı',
    'Çalışan Cevap Sayısı',
    'PROVİDER',
    'Kayıt Tarihi'
  ];

  useEffect(() => {
    const getData = async () => {
      await api.global
        .list('user', page)
        .then(async res => {
          setData(res.data);
        })
        .catch(error => console.log('error', error));
    };
    getData();
  }, []);

  return (
    <main className="w-full">
      <AppTable
        titles={titles}
        rows={data}
        type="stores"
        hideRows="__v,_id,updatedAt,locale,picture,enabledCampaigns,providerUserId"
      />
    </main>
  );
};

export default UserList;
