export const calculateQrStatistics = data => {
  const today = new Date();

  const qrScanningCounts = {
    total: data.length,
    appTotal: 0,
    appMonthly: 0,
    appWeekly: 0,
    appDaily: 0,
    webTotal: 0,
    webMonthly: 0,
    webWeekly: 0,
    webDaily: 0
  };

  for (let i = 0; i < data.length; i++) {
    const stat = data[i];
    const statDate = new Date(stat.createdAt);
    const diffTime = Math.abs(statDate - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (stat.scanType === 'app') {
      qrScanningCounts.appTotal++;
      if (diffDays <= 30) {
        qrScanningCounts.appMonthly++;
        if (diffDays <= 7) {
          qrScanningCounts.appWeekly++;
        }
        if (diffDays <= 1) {
          qrScanningCounts.appDaily++;
        }
      }
    }

    if (stat.scanType === 'web') {
      qrScanningCounts.webTotal++;
      if (diffDays <= 30) {
        qrScanningCounts.webMonthly++;
        if (diffDays <= 7) {
          qrScanningCounts.webWeekly++;
        }
        if (diffDays <= 1) {
          qrScanningCounts.webDaily++;
        }
      }
    }
  }

  return qrScanningCounts;
};
