import React from 'react';
import { useAuth } from 'context/AuthContext';

const AppHeader = ({ pageName }) => {
  const { user } = useAuth();

  return (
    <div className="flex justify-between items-center mb-10">
      <h1 className="text-xl font-bold text-slate-900">{pageName}</h1>
      <div className="flex items-center">
        <span className="text-sm font-medium text-slate-50 border px-3 py-1 rounded bg-slate-900 ">
          {user.name}
        </span>
      </div>
    </div>
  );
};

export default AppHeader;
