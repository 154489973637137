import { AppButton, AppFile, AppModal, AppSelect } from 'components';
import { categoryNames } from 'constants/menuTemplates';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import api from 'services/api';
import { uploadCareDeleteFile } from 'utils/uploadCare';

const AddMenuCategoryTemplateModal = ({ onClose, isOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [categoryPhoto, setCategoryPhoto] = useState(null);
  const [uploadedPhotoId, setUploadedPhotoId] = useState();

  //form values
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm({});

  const onSubmit = async submitData => {
    try {
      await api.global.post('menu-category-template', {
        categoryName: submitData.categoryName.label,
        categoryPhoto: categoryPhoto
      });

      location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = () => {
    if (uploadedPhotoId) {
      uploadCareDeleteFile(uploadedPhotoId);
      setUploadedPhotoId(null);
    }
    onClose();
  };

  return (
    <AppModal
      open={isOpen}
      title="Kategori Fotoğrafı Ekle"
      handleClose={() => closeModal()}
    >
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppFile
          error=""
          selectedImage={categoryPhoto}
          label="Fotoğraf"
          onCompleted={file => {
            setCategoryPhoto(file.cdnUrl);
            setUploadedPhotoId(file.uuid);
          }}
          crop="5:1"
        />
        <AppSelect
          options={categoryNames}
          label="Kategori İsmi"
          name="categoryName"
          error={errors.categoryName}
          rules={{ required: true }}
          control={control}
          placeholder="Kategori"
        />

        <AppButton type="submit" className="mt-5" isLoading={isLoading}>
          Ekle
        </AppButton>
      </form>
    </AppModal>
  );
};

export default AddMenuCategoryTemplateModal;
