export default {
  _formErrorMessages: {
    required: '{{label}} alanı zorunludur.',
    minLength: '{{label}} alanını eksiksiz girmelisin.',
    pattern: '{{label}} alanına lütfen geçerli bir değer giriniz.',
    wrongData: '{{label}} alanını yanlış girdiniz.'
  },
  _auth: {
    login: 'Giriş Yap',
    register: 'Kayıt Ol',
    forgotPassword: 'Şifremi Unuttum',
    resetPassword: 'Şifremi Sıfırla',
    logout: 'Çıkış Yap',
    email: 'E-Posta',
    password: 'Şifre',
    enterEmail: 'E-Posta Adresinizi Giriniz',
    enterPassword: 'Şifrenizi Giriniz',
    loginDescription:
      'Lütfen aşağıdaki butona tıklayarak Google ile giriş yapınız. Bu işlem yalnızca onaylanmış kullanıcılar için geçerlidir.',
    loginWithGoogle: 'Google ile Giriş Yap',
    loginAccessDenied:
      'Giriş yapmak için yetkiniz yok. Lütfen geçerli e-posta adresinizle giriş yapınız.'
  },
  _general: {
    save: 'Kaydet',
    close: 'Kapat',
    continue: 'Devam Et',
    delete: 'Sil'
  },
  _alerts: {
    successTitle: 'Başarılı!',
    successSubtitle: 'Değişikler başarıyla kaydedildi.',
    errorTitle: 'Başarısız!',
    errorSubtitle: 'Değişikler malesef kaydedilemedi.'
  },
  _customer: {
    _id: 'Customer ID',
    firstName: 'Ad',
    lastName: 'Soyad',
    email: 'E-posta',
    password: 'Şifre',
    isRegister: 'Kayıt Durumu',
    isVerified: 'Onay Durumu',
    allowedStoreNumber: 'İzin Verilen İşletme',
    allowedCampaignNumber: 'İzin Verilen Kampanya',
    isCampaignEnable: 'Kampanya Kullanılabilirliği',
    createdAt: 'Kayıt Tarihi',
    updatedAt: 'Son Güncelleme Tarihi'
  },
  _storeTable: {
    _id: 'Store ID',
    isActive: 'İşletme Aktifliği',
    logo: 'Logo',
    storeType: 'İşletme Türü',
    customerId: 'Müşteri ID',
    isMenuActive: 'Menü Aktifliği',
    surveyAnswersCount: 'Anket Cevap Sayısı',
    workersAnswersCount: 'Çalışan Oylama Sayısı',
    createdAt: 'Kayıt Tarihi',
    customerFullName: 'Müşteri Ad Soyad',
    storeName: 'İşletme Adı',
    updatedAt: 'Son Etkileşim',
    visibleSurveyId: 'Yayındaki Anket ID',
    position: 'Konum',
    city: 'İl',
    district: 'İlçe',
    themeColor: 'Tema',
    customerMail: 'Müşteri E-mail'
  },
  _survey: {
    question: 'Soru',
    answer: 'Cevap'
  },
  _statistics: {
    customer: 'Müşteri',
    store: 'İşletme',
    surveyAnswer: 'Anket Cevapları',
    workerAnswer: 'Çalışan Cevapları',
    user: 'Kullanıcı',
    surveyTemplate: 'Anket Örnekleri',
    menuCategoryTemplate: 'Menü Kategori Örnekleri',
    menuProductTemplate: 'Menü Ürün Örnekleri',
    webContactForm: 'Website İletişim Form Sayısı',
    webInfoMail: 'Web Info Mail Sayısı'
  }
};
