export const allowedStoreArray = [...Array(20)].map((item, index) => ({
  label: index + 1,
  value: index + 1
}));

export const monthPeriod = [...Array(120)].map((item, index) => ({
  label: index + 1,
  value: index + 1
}));

export const packageTypes = [
  {
    label: 'Free',
    value: 'free'
  },
  {
    label: 'Premium',
    value: 'premium'
  }
];
