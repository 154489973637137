import { AppButton, AppModalAlert, AppSelect, AppTable } from 'components';
import { allowedStoreArray } from 'constants/packageTypes';
import { t } from 'locales';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import api from 'services/api';
import { checkValue, getValue } from 'utils/table';

export default function CustomerDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [stores, setStores] = useState([]);
  const [modal, setModal] = useState({
    isOpen: false,
    type: null
  });
  const hiddenProperties = 'status,storeIds,__v,fullName,password';

  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit
  } = useForm({
    defaultValues: {
      allowedStoreNumber: 0
    }
  });

  useEffect(() => {
    const getData = async () => {
      await api.global
        .get('customer', id)
        .then(async res => {
          const resCustomer = res.data.customer;
          if (resCustomer.storeIds && resCustomer.storeIds.length > 0) {
            await getStores(resCustomer._id);
          }
          setData(resCustomer);
          const allowedStoreNumberType = allowedStoreArray.find(
            type => type.value === resCustomer.allowedStoreNumber
          );
          setValue('allowedStoreNumber', allowedStoreNumberType);
        })
        .catch(err => console.log(err));
    };
    getData();
    return () =>
      setModal({
        isOpen: false,
        type: null
      });
  }, []);

  const getStores = async customerId => {
    await api.global
      .get('customer', `stores/${customerId}`)
      .then(res => setStores(res.data.store))
      .catch(err => console.log(err));
  };

  const onSubmit = async submitData => {
    setIsLoading(true);
    await api.global
      .update('customer', id, {
        allowedStoreNumber: submitData.allowedStoreNumber.value
      })
      .then(res => {
        setModal({
          isOpen: true,
          type: 'success'
        });
      })
      .catch(err => {
        setModal({
          isOpen: true,
          type: 'error'
        });
        console.log(err.message);
      });
    setIsLoading(false);
  };

  const confirmDelete = async () => {
    if (window.confirm('Silme işlemi geri alınamaz. Onaylıyor musunuz?')) {
      await api.global.delete('customer', id);
      navigate('/customers');
    }
  };
  return (
    <main className="w-full flex flex-col px-5">
      <h2 className="text-3xl font-bold text-slate-700 mb-4 text-center">
        Müşteri Detayı
      </h2>
      <div className="justify-between items-center w-full flex flex-row mt-12">
        <div className="w-2/5 flex flex-col">
          <h3 className="text-xl font-bold text-slate-700 mb-4">
            Müşteri Bilgileri
          </h3>
          {Object.keys(data).map(
            (key, index) =>
              checkValue(key, hiddenProperties) && (
                <span
                  className="py-3 text-lg border-b-2 text-slate-900"
                  key={index}
                >
                  <span className="font-semibold">{`${t(
                    `_customer.${key}`
                  )}:`}</span>
                  <span className="ml-2 text-slate-600">
                    {getValue(data[key])}
                  </span>
                </span>
              )
          )}
          <AppButton
            className="mt-5 !bg-red-500 hover:!bg-red-400"
            onClick={confirmDelete}
          >
            {t('_general.delete')}
          </AppButton>
        </div>
        <form className="w-2/5" onSubmit={handleSubmit(onSubmit)}>
          <h3 className="text-xl font-bold text-slate-700 mb-4">
            İşletme İzni Ekle
          </h3>
          <AppSelect
            options={allowedStoreArray}
            label="İşletme Sayısı"
            name="allowedStoreNumber"
            error={errors.allowedStoreNumber}
            rules={{ required: true }}
            control={control}
            placeholder="İzin verilen işletme sayısı"
          />
          <AppButton type="submit" className="mt-5" isLoading={isLoading}>
            {t('_general.save')}
          </AppButton>
        </form>
      </div>
      <div className="w-full mt-12">
        <h3 className="text-xl font-bold text-slate-700 mb-4">İşletmeler</h3>
      </div>
      <AppTable
        titles={[
          'İşletme İsmi',
          'Logo',
          'Menü Aktifliği',
          'Toplam Anket Cevabı',
          'Toplam Çalışan Oylama',
          'İşletme Aktifliği',
          'Kayıt Tarihi'
        ]}
        rows={stores}
        type="stores"
        isDetail={true}
        hideRows="allSurveys,menu,storeWorkers,updatedAt,visibleSurveyId,customerId,__v,_id,location,storeType,qrScannedCount,themeColor,socialLinks"
      />
      <AppModalAlert
        isOpen={modal.isOpen}
        type={modal.type}
        onClose={() =>
          modal.type === 'success'
            ? navigate('/customers')
            : setModal({
                isOpen: false,
                type: null
              })
        }
      ></AppModalAlert>
    </main>
  );
}
