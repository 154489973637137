import {
  UploadcareSimpleAuthSchema,
  deleteFile
} from '@uploadcare/rest-client';

const uploadcareSimpleAuthSchema = new UploadcareSimpleAuthSchema({
  publicKey: process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY,
  secretKey: process.env.REACT_APP_UPLOADCARE_SECRET_KEY
});

export const uploadCareDeleteFile = async url => {
  if (url.includes('http')) {
    const splittedUrl = url.split('com/');
    const uuid = splittedUrl[1].split('/')[0];
    if (uuid) {
      await deleteFile(
        {
          uuid: uuid
        },
        { authSchema: uploadcareSimpleAuthSchema }
      );
    }
  } else {
    await deleteFile(
      {
        uuid: url
      },
      { authSchema: uploadcareSimpleAuthSchema }
    );
  }
};
