import DashboardLayout from 'layouts/dashboard.layout';
import AuthLayout from 'layouts/auth.layout';
import React from 'react';
import Login from './Login.screen';
import Home from './Home.screen';
import CustomerList from './customers/CustomerList.screen';
import StoreList from './stores/StoreList.screen';
import UserList from './users/UserList.screen';
import CustomerDetail from './customers/CustomerDetail.screen';
import StoreDetail from './stores/StoreDetail.screen';
import Survey from './survey/Survey.screen';
import Menu from './menu/Menu.screen';
import WebsiteContactForm from './website/WebsiteContactForm.screen';
import WebsiteInfoMail from './website/WebsiteInfoMail.screen';
import CampaignList from './campaigns/CampaignList.screen';

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        path: 'customers',
        element: <CustomerList />
      },
      {
        path: 'customers/detail/:id',
        element: <CustomerDetail />
      },
      {
        path: 'stores',
        element: <StoreList />
      },
      {
        path: 'stores/detail/:id',
        element: <StoreDetail />
      },
      {
        path: 'users',
        element: <UserList />
      },
      {
        path: 'campaigns',
        element: <CampaignList />
      },
      {
        path: 'survey',
        element: <Survey />
      },
      {
        path: 'menu',
        element: <Menu />
      },
      {
        path: 'website/contactForms',
        element: <WebsiteContactForm />
      },
      {
        path: 'website/infoMail',
        element: <WebsiteInfoMail />
      }
    ]
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: <Login />
      }
    ]
  }
];

export default routes;
