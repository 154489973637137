import {
  BarChart,
  CancelPresentation,
  CheckCircle,
  Diversity3,
  QuestionAnswer
} from '@mui/icons-material';
import { AppButton, AppTable } from 'components';
import { storeTypes } from 'constants/store';
import { t } from 'locales';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from 'services/api';
import { calculateQrStatistics } from 'utils/statistics';
import { arrangeStoreDetails } from 'utils/store';
import { calculateQuestionRatePoint } from 'utils/survey';
import { checkValue, getValue } from 'utils/table';

export default function StoreDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const [data, setData] = useState([]);
  const [statistics, setStatistics] = useState([]);

  const hiddenProperties =
    'allSurveys,__v,storeWorkers,menu,location,visibleSurvey,socialLinks,qrScannedCount';
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';

  const appQrStatisticValues = [
    {
      label: 'Toplam Qr Okutma',
      property: 'total',
      bgColor: 'bg-red-500',
      textColor: 'text-red-500'
    },
    {
      label: 'Toplam App Qr Okutma',
      property: 'appTotal',
      bgColor: 'bg-sky-500',
      textColor: 'text-sky-500'
    },
    {
      label: 'Aylık App Qr Okutma',
      property: 'appMonthly',
      bgColor: 'bg-sky-500',
      textColor: 'text-sky-500'
    },
    {
      label: 'Haftalık App Qr Okutma',
      property: 'appWeekly',
      bgColor: 'bg-sky-500',
      textColor: 'text-sky-500'
    },
    {
      label: 'Günlük App Qr Okutma',
      property: 'appDaily',
      bgColor: 'bg-sky-500',
      textColor: 'text-sky-500'
    }
  ];

  const webQrStatisticValues = [
    {
      label: 'Toplam Web Qr Okutma',
      property: 'webTotal',
      bgColor: 'bg-emerald-500',
      textColor: 'text-emerald-500'
    },
    {
      label: 'Aylık Web Qr Okutma',
      property: 'webMonthly',
      bgColor: 'bg-emerald-500',
      textColor: 'text-emerald-500'
    },
    {
      label: 'Haftalık Web Qr Okutma',
      property: 'webWeekly',
      bgColor: 'bg-emerald-500',
      textColor: 'text-emerald-500'
    },
    {
      label: 'Günlük Web Qr Okutma',
      property: 'webDaily',
      bgColor: 'bg-emerald-500',
      textColor: 'text-emerald-500'
    }
  ];

  useEffect(() => {
    const getData = async () => {
      await api.global.get('store', id).then(async res => {
        let customerData;

        await api.global
          .get('customer', res.data.store.customerId)
          .then(async res => {
            customerData = res.data.customer;
          })
          .catch(err => console.log(err, 'customer error'));

        const resData = arrangeStoreDetails(res.data.store, customerData);

        const statisticRes = await api.statistic.getStoreQrScannedStatistics(
          resData._id
        );

        const calculatedStats = calculateQrStatistics(
          statisticRes.data.statistics
        );

        setStatistics(calculatedStats);

        setData(resData);
      });
    };
    getData();
  }, []);

  const getInfoPropertyValues = property => {
    if (property === 'position') {
      return `${data.location[property].lat}, ${data.location[property].lng}`;
    } else if (property === 'city') {
      return `${data.location[property]}`;
    } else if (property === 'district') {
      return `${data.location[property]}`;
    } else if (property === 'storeType') {
      const type = storeTypes.find(item => item.value === data[property]);
      return `${type.label}`;
    }
    return getValue(data[property], true, false);
  };

  const deleteStore = async () => {
    if (window.confirm('Silme işlemi geri alınamaz. Onaylıyor musunuz?')) {
      try {
        await api.global.delete('store', id);
        navigate('/stores');
      } catch (err) {
        console.log(err);
      }
    }
  };

  const changeStoreAccess = async () => {
    if (
      window.confirm('Bu mağaza bilgilerine erişilemez. Onaylıyor musunuz?')
    ) {
      try {
        await api.global.update('store-access', id, {
          status: !data?.isActive
        });
        location.reload();
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <main className="w-full flex flex-col px-5">
      <h2 className="text-3xl font-bold text-slate-700 mb-4 text-center">
        İşletme Detayı
      </h2>
      <div className="flex flex-row justify-between items-center">
        <h3 className="text-xl font-bold text-slate-700 mb-4 mt-4">
          Müşteri Bilgileri
        </h3>
        <div className="flex flex-row items-center">
          <AppButton
            onClick={() => changeStoreAccess()}
            className="!bg-blue-500 !text-white hover:!bg-blue-400 focus:!ring-blue-300  mr-4"
            isFullWidth={false}
          >
            {data?.isActive ? 'Askıya al' : 'Etkinleştir'}
          </AppButton>
          <AppButton
            onClick={() => deleteStore()}
            className="!bg-red-500 !text-white hover:!bg-red-400 focus:!ring-red-300 "
            isFullWidth={false}
          >
            Sil
          </AppButton>
        </div>
      </div>
      {Object.keys(data).map(
        (key, index) =>
          checkValue(key, hiddenProperties) && (
            <span
              className="py-3 text-lg flex items-center flex-row border-b border-slate-300"
              key={index}
            >
              <span className="font-semibold  text-slate-900">{`${t(
                `_storeTable.${key}`
              )}:`}</span>
              <span className="ml-2  text-slate-600">
                {getInfoPropertyValues(key)}
              </span>
            </span>
          )
      )}
      <div className="bg-white mt-8 p-6 border border-gray-200 rounded-md ">
        <span className="flex flex-row justify-center">
          <h4 className="text-lg font-semibold text-slate-900">
            <BarChart fontSize="inherit" className="mr-2" />
            Qr Okutma Bilgileri
          </h4>
        </span>
        <div className="grid gap-4 grid-cols-2 mt-4">
          <div>
            {appQrStatisticValues.map((value, i) => (
              <div
                key={i}
                className="flex flex-row justify-between mt-4 border-b border-gray-300 pb-1"
              >
                <span className="flex flex-row items-center">
                  <span className={`w-1 h-1 rounded-full ${value.bgColor}`} />
                  <span className="text-gray-900 font-medium text-sm ml-2">
                    {value.label}
                  </span>
                </span>
                <span
                  className={`${value.textColor} tracking-tight font-medium text-sm`}
                >
                  {statistics[value.property]}
                </span>
              </div>
            ))}
          </div>
          <div>
            {webQrStatisticValues.map((value, i) => (
              <div
                key={i}
                className="flex flex-row justify-between mt-4 border-b border-gray-300 pb-1"
              >
                <span className="flex flex-row items-center">
                  <span className={`w-1 h-1 rounded-full ${value.bgColor}`} />
                  <span className="text-gray-900 font-medium text-sm ml-2">
                    {value.label}
                  </span>
                </span>
                <span
                  className={`${value.textColor} tracking-tight font-medium text-sm`}
                >
                  {statistics[value.property]}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-10 bg-white p-4 border border-slate-200 rounded-md">
        <span className="flex flex-row justify-center">
          <h4 className="text-lg font-semibold text-slate-900">
            <QuestionAnswer fontSize="inherit" className="mr-2" />
            Yayındaki Anket
          </h4>
        </span>

        {data?.visibleSurvey ? (
          <>
            <span className="text-slate-900 font-semibold pb-2 border-b border-slate-200 block">
              Anket ismi: {data.visibleSurvey.surveyName}
            </span>

            {data.visibleSurvey.questions.map((question, index) => (
              <div
                key={question._id}
                className={`flex w-full py-2 flex-col ${
                  index + 1 !== data.visibleSurvey.questions.length &&
                  'border-b'
                }`}
              >
                <div>
                  <span className="font-semibold text-slate-900">
                    {index + 1}-
                  </span>
                  <span className="ml-1 font-medium text-slate-900">
                    {question.question}
                  </span>
                </div>
                <span className="text-slate-900 text-sm font-medium py-2 ml-5 border-b border-slate-200 block">
                  Soru türü:
                  {question.questionType === 'explanation' && ' Açıklama'}
                  {question.questionType === 'multipleChoices' &&
                    ' Çoktan Seçmeli'}
                  {question.questionType === 'rate' && ' Oylama'}
                </span>
                <span className="text-slate-900 text-sm font-medium py-2 ml-5 border-b border-slate-200 block">
                  Cevap zorunluluğu:
                  {question?.isRequired ? (
                    <CheckCircle
                      className="text-green-500 ml-1"
                      fontSize="small"
                    />
                  ) : (
                    <CancelPresentation
                      className="text-red-500 ml-1"
                      fontSize="small"
                    />
                  )}
                </span>
                {question.questionType === 'multipleChoices' && (
                  <>
                    {question.multipleChoices.map((choice, i) => (
                      <div
                        key={choice._id}
                        className={`text-sm ml-5 py-2 flex flex-row justify-between ${
                          i + 1 !== question.multipleChoices.length &&
                          'border-b'
                        }`}
                      >
                        <span>
                          <span className="font-semibold text-slate-900">{`${alphabet[i]}-)`}</span>
                          <span className="ml-1 font-medium text-slate-900">
                            {choice.choice}
                          </span>
                        </span>
                        <span className="text-sm text-slate-900 font-medium">
                          {choice.chooseCount}
                          <BarChart
                            className="ml-2 text-blue-500"
                            fontSize="small"
                          />
                        </span>
                      </div>
                    ))}
                  </>
                )}
                {question?.questionType === 'rate' && (
                  <>
                    <span className="w-full text-slate-900  text-sm font-medium flex flex-row  py-2 ml-5 border-b border-slate-200">
                      Toplam oylama sayısı:
                      {question.rateAnswerStatistics.rateCount}
                    </span>
                    <span className="w-full flex flex-row py-2 ml-5 text-slate-900 text-sm font-medium  border-b border-slate-200">
                      Oylama yüzdesi:
                      {calculateQuestionRatePoint(
                        question.rateAnswerStatistics.ratePoint,
                        question.rateAnswerStatistics.rateCount
                      )}
                    </span>
                  </>
                )}
              </div>
            ))}
          </>
        ) : (
          <span>Anket oluşturulmadı</span>
        )}
      </div>

      <div className="mt-10">
        <h4 className="text-xl font-bold text-slate-900 mb-4">
          <Diversity3 fontSize="inherit" className="mr-2" /> İşletme Çalışanları
        </h4>
        {data.storeWorkers && data.storeWorkers.length > 0 ? (
          <AppTable
            titles={[
              'Fotoğraf',
              'İsim',
              'Soyisim',
              'Meslek',
              'Toplam Oylama Yüzdesi',
              'Toplam Oylama Sayısı',
              'Aktiflik Durumu'
            ]}
            rows={data.storeWorkers}
            type="workers"
            hideRows="_id"
          />
        ) : (
          <span>Herhangi bir çalışan eklenmedi.</span>
        )}
      </div>
    </main>
  );
}
