import React from 'react';
import { Widget } from '@uploadcare/react-widget';
import { t } from 'locales';

const AppFile = ({
  label,
  error,
  crop = '16:9',
  isLabelActive = true,
  onCompleted,
  multiple = false,
  multipleMax = 7,
  selectedImage
}) => {
  const UPLOADCARE_PUBLIC_KEY = process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY;
  return (
    <div className="w-full mb-5 flex flex-col justify-center items-center">
      {isLabelActive && (
        <label
          htmlFor="appFile"
          className={`text-sm font-medium ${
            error ? 'text-red-600' : 'text-gray-900'
          }`}
        >
          Fotoğraf
        </label>
      )}

      <img
        src={selectedImage || require('../../assets/images/default-image.png')}
        className={`h-16 w-auto object-contain mt-2 mb-4 ${
          !selectedImage && 'border border-gray-200 rounded-full'
        }`}
      />

      <Widget
        publicKey={UPLOADCARE_PUBLIC_KEY}
        onChange={onCompleted}
        imagesOnly
        locale="tr"
        effects={'crop'}
        crop={crop}
        multiple={multiple}
        multipleMax={multipleMax}
      />
    </div>
  );
};

export default AppFile;
