import React, { useEffect, useState } from 'react';
import { AppTable } from 'components';
import { useSearchParams } from 'react-router-dom';
import api from 'services/api';
import { calculateQrStatistics } from 'utils/statistics';

const CustomerList = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);

  const titles = [
    'İşletme İsmi',
    'Logo',
    'Menü Aktifliği',
    'Anket Cevabı',
    'Çalışan Cevabı',
    'İşletme Aktifliği',
    'Kayıt Tarihi',
    'App Qr Okutma',
    'Web Qr Okutma'
  ];

  useEffect(() => {
    const getData = async () => {
      await api.global
        .list('store', page)
        .then(async res => {
          const resStores = res.data;
          for (let i = 0; i < resStores.length; i++) {
            let store = resStores[i];

            const statisticRes =
              await api.statistic.getStoreQrScannedStatistics(store._id);

            const statistics = calculateQrStatistics(
              statisticRes.data.statistics
            );

            store.appQrScannedCount = statistics.appTotal;
            store.webQrScannedCount = statistics.webTotal;
          }

          setData(resStores);
        })
        .catch(error => console.log('error', error));
    };
    getData();
  }, []);

  return (
    <main className="w-full">
      <AppTable
        titles={titles}
        rows={data}
        type="stores"
        isDetail={true}
        hideRows="__v,_id,updatedAt,customerId,qrScannedCount,socialLinks,storeType,storeWorkers,visibleSurveyId,menu,location,allSurveys,themeColor"
      />
    </main>
  );
};

export default CustomerList;
