import { calculateWorkerPoint } from './workers.js';

export const arrangeStoreDetails = (store, customerData) => {
  const visibleSurvey = store.allSurveys.find(
    survey => survey._id === store.visibleSurveyId
  );
  store.visibleSurvey = visibleSurvey;
  store.position = store.location.position;
  store.city = store.location.city;
  store.district = store.location.district;
  store.customerMail = customerData?.email;
  store.customerFullName = customerData?.fullName;

  for (let i = 0; i < store.storeWorkers.length; i++) {
    const worker = store.storeWorkers[i];
    if (typeof worker.ratePoint !== 'string') {
      worker.ratePoint = calculateWorkerPoint(
        worker.ratePoint,
        worker.rateCount
      );
    }
  }

  return store;
};
