import { t } from 'locales';
import React, { useEffect, useState } from 'react';
import api from 'services/api';

const Home = () => {
  const [statistics, setStatistics] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await api.global
        .get('collections', 'statistics')
        .then(async res => {
          setStatistics(res.data.statistics);
        })
        .catch(error => console.log('error', error));
      setLoading(false);
    };
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <main className="w-1/2 ml-auto mr-auto">
          <h1 className="text-center text-slate-900 font-medium text-xl">
            Veriler
          </h1>
          <p className="mt-4 font-sm text-slate-600">
            Bu sayfada Repidasa ait koleksiyon sayılarını görebilirsiniz.
          </p>
          <span className="mt-4 inline-block font-medium text-slate-900">
            Toplam Koleksiyon Sayıları
          </span>
          <div className="grid grid-cols-1 bg-white  flex-col w-full mt-4 rounded-md border">
            {statistics.map((item, index) => (
              <div
                key={index}
                className={`py-6 px-4 flex items-center justify-between bg-white rounded-t-md border-b ${
                  index + 1 === statistics.length && 'border-b-0 rounded-b-md'
                }`}
              >
                <span className="text-slate-900 font-medium">
                  {t(`_statistics.${item.name}`)}
                </span>
                <span className="text-slate-900 font-medium">
                  {item.collectionLength}
                </span>
              </div>
            ))}
          </div>
        </main>
      )}
    </>
  );
};

export default Home;
