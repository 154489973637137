export const storeTypes = [
  {
    label: 'Restoran/kafe',
    value: 'restaurant-cafe'
  },
  {
    label: 'Restoran',
    value: 'restaurant'
  },
  {
    label: 'Kafe',
    value: 'cafe'
  },
  {
    label: 'Otel',
    value: 'hotel'
  },
  {
    label: 'Bar',
    value: 'bar'
  },
  {
    label: 'Diğer',
    value: 'other'
  }
];
