import React, { useEffect, useState } from 'react';
import { AppButton, AppTable } from 'components';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';

const CustomerList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [page] = useState(0);

  const titles = [
    'E-mail',
    'Ad',
    'Soyad',
    'İzin Verilen İşletme',
    'Kampanya Kullanılabilirliği',
    'Onay',
    'Üyelik Tarihi'
  ];

  useEffect(() => {
    const getData = async () => {
      await api.global
        .list('customer', page)
        .then(async res => {
          setData(res.data);
        })
        .catch(error => console.log('error', error));
    };
    getData();
  }, []);

  return (
    <main className="w-full">
      <AppTable
        titles={titles}
        rows={data}
        type="customers"
        isDetail={true}
        hideRows="__v,_id,updatedAt,storeIds,password,fullName,allowedCampaignNumber"
      />
    </main>
  );
};

export default CustomerList;
