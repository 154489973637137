import { Add, ControlPoint, Remove } from '@mui/icons-material';
import {
  AppButton,
  AppInput,
  AppModal,
  AppSelect,
  AppSwitch
} from 'components';
import { questionTypes } from 'constants/survey';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import api from 'services/api';
import { changeQuestionType } from 'utils/survey';

const UpdateSurveyTemplateModal = ({ survey, onClose, isOpen }) => {
  const [isLoading, setIsLoading] = useState(true);

  //form values
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
    getValues,
    setValue
  } = useForm({
    defaultValues: {
      surveyName: survey.surveyName,
      questions: survey.questions
    }
  });

  useEffect(() => {
    const init = () => {
      setIsLoading(true);
      const defaultQuestions = getValues('questions');
      setValue('questions', changeQuestionType(defaultQuestions));
      setIsLoading(false);
    };
    init();
  }, []);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions'
  });

  const onSubmit = async submitData => {
    for (let i = 0; i < submitData.questions.length; i++) {
      const surveyQuestion = submitData.questions[i];
      surveyQuestion.questionType = surveyQuestion.questionType.value;
      if (surveyQuestion.questionType !== 'multipleChoices') {
        delete surveyQuestion.multipleChoices;
      }
    }

    try {
      await api.global.update('survey-template', survey._id, submitData);
      location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <AppModal
          open={isOpen}
          title="Anket Oluştur"
          handleClose={() => onClose()}
        >
          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <AppInput
              label="Anket İsmi"
              name="surveyName"
              error={errors.surveyName}
              rules={{ required: true }}
              register={register}
              placeholder="Anket İsmi"
            />
            <ul>
              {fields.map((item, index) => {
                return (
                  <div key={item.id} className={`flex flex-col`}>
                    <div
                      className={`flex flex-row items-center justify-between border-slate-300 border-b-2 pb-1 mb-3`}
                    >
                      <span className="text-slate-900 font-medium">
                        {`${index + 1}. Soru`}
                      </span>
                      {index !== 0 && (
                        <AppButton
                          isFullWidth={false}
                          onClick={() => {
                            remove(index);
                          }}
                          className="!bg-transparent border !border-red-300 !text-red-500 hover:!bg-red-100"
                        >
                          {index + 1}. Soruyu Sil
                        </AppButton>
                      )}
                    </div>

                    <li className="flex flex-col w-full">
                      <AppInput
                        label={`Soru`}
                        name={`questions.${index}.question`}
                        error={
                          errors?.questions &&
                          errors?.questions[index]?.question
                        }
                        register={register}
                        rules={{ required: true }}
                        placeholder={`Soru`}
                        autoComplete="off"
                      />
                      <AppSelect
                        options={questionTypes}
                        label={`Soru Modeli`}
                        name={`questions.${index}.questionType`}
                        error={
                          errors?.questions &&
                          errors?.questions[index]?.questionType
                        }
                        rules={{ required: true }}
                        control={control}
                        placeholder={`${index + 1}. Soru Modeli`}
                      />
                      {watch(`questions.${index}.questionType`).value ===
                        'multipleChoices' && (
                        <>
                          <div className="w-full flex mb-2 justify-between items-center">
                            <span className="block text-sm font-medium text-gray-900">
                              Seçenekler
                            </span>
                            <AppButton
                              className="!p-1 text-gray-900 focus:ring-1 focus:ring-gray-800 hover:bg-gray-100 text-sm bg-transparent rounded-md flex justify-center items-center border border-gray-300"
                              type="button"
                              onClick={() => {
                                const currentChoices =
                                  getValues('questions')[index].multipleChoices;
                                currentChoices.push({
                                  choice: '',
                                  chooseCount: 0
                                });
                                setValue(
                                  `questions.${index}.multipleChoices`,
                                  currentChoices
                                );
                              }}
                              isFullWidth={false}
                            >
                              <Add fontSize="small" />
                            </AppButton>
                          </div>
                          {watch(`questions.${index}.multipleChoices`).map(
                            (choice, i) => (
                              <div
                                key={i}
                                className="flex w-full flex-row items-center "
                              >
                                <span className="mr-2 text-xs text-gray-900  mb-5">
                                  {`${i + 1}.`}
                                </span>
                                <AppInput
                                  label={`${i + 1}. Seçenek`}
                                  isLabelActive={false}
                                  name={`questions.${index}.multipleChoices[${i}].choice`}
                                  error={
                                    errors?.questions &&
                                    errors?.questions[index]?.multipleChoices[i]
                                      ?.choice
                                  }
                                  rules={{ required: true }}
                                  register={register}
                                  placeholder={`Seçenek`}
                                  autoComplete="off"
                                />
                                {i > 1 && (
                                  <AppButton
                                    className="!p-2 mb-5 ml-2 text-gray-900 focus:ring-1 focus:ring-gray-800 hover:bg-gray-100 text-sm bg-transparent rounded-md flex justify-center items-center border border-gray-300"
                                    type="button"
                                    onClick={() => {
                                      const currentChoices =
                                        getValues('questions')[index]
                                          .multipleChoices;

                                      currentChoices.splice(i, 1);
                                      setValue(
                                        `questions.${index}.multipleChoices`,
                                        currentChoices
                                      );
                                    }}
                                    isFullWidth={false}
                                  >
                                    <Remove fontSize="small" />
                                  </AppButton>
                                )}
                              </div>
                            )
                          )}
                        </>
                      )}
                      <AppSwitch
                        control={control}
                        name={`questions.${index}.isRequired`}
                        label="Cevap Zorunlu mu ?"
                      />
                    </li>
                  </div>
                );
              })}
            </ul>

            <AppButton
              type="button"
              onClick={() => {
                append({
                  question: '',
                  questionType: '',
                  isRequired: true,
                  multipleChoices: [{ choice: '', chooseCount: 0 }],
                  rateAnswerStatistics: { ratePoint: 0, rateCount: 0 }
                });
              }}
              className="mt-5 bg-transparent border hover:!bg-gray-100 border-gray-300 !text-gray-800"
              isLoading={isLoading}
            >
              Soru Ekle
              <ControlPoint className="ml-1" />
            </AppButton>
            <AppButton type="submit" className="mt-5" isLoading={isLoading}>
              Anketi Güncelle
            </AppButton>
          </form>
        </AppModal>
      )}
    </>
  );
};

export default UpdateSurveyTemplateModal;
