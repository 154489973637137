import React from 'react';
import { t } from 'locales';
import Modal from 'react-modal';
import AppButton from 'components/AppButton/AppButton.component';
import {
  CheckCircleOutlineRounded,
  HighlightOffRounded
} from '@mui/icons-material';

const AppModalAlert = ({ isOpen, type = 'success', onClose }) => {
  const isSuccess = type === 'success';
  const customStyles = {
    content: {
      top: '50%',
      left: '58%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderWidth: '1px',
      borderRadius: '12px'
    },
    overlay: {
      backgroundColor: 'rgba(255, 255, 255, 0.40)'
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      appElement={document.getElementById('root')}
    >
      <div
        className={`py-4 px-12 mb-4 text-sm  rounded-lg w-full my-4 flex flex-col items-center ${
          isSuccess ? 'text-green-700 bg-green-100' : 'text-red-500 bg-red-50'
        }`}
        role="alert"
      >
        <span className="font-medium mb-1">
          {t(`_alerts.${type}Title`)}
          <span className="ml-2 text-lg">
            {isSuccess ? (
              <CheckCircleOutlineRounded fontSize="inherit" />
            ) : (
              <HighlightOffRounded />
            )}
          </span>
        </span>
        {t(`_alerts.${type}Subtitle`)}
      </div>
      <AppButton
        type="submit"
        className="bg-white border-slate-700 border-2 !text-slate-700 hover:bg-white hover:border-slate-400"
        onClick={onClose}
      >
        {t('_general.close')}
      </AppButton>
    </Modal>
  );
};

export default AppModalAlert;
