import React from 'react';
import { dateToStringWithTime } from './date';

export const getValue = (
  value,
  imageIsBorderless = false,
  isSubstringActive = true
) => {
  if (typeof value === 'string') {
    if (value.length < 1) {
      return '-';
    } else if (value.includes('http')) {
      return (
        <div className="flex items-center justify-center">
          <img
            src={value}
            className={`h-10 w-10 object-contain ${
              !imageIsBorderless && 'rounded-full border'
            }`}
          />
        </div>
      );
    }

    if (!isNaN(new Date(value).getDate()) && value.length > 15) {
      return `${dateToStringWithTime(value)}`;
    }
    // if (isSubstringActive && value.length > 20) {
    //   return value.substring(0, 20) + '...';
    // }
    return value;
  }

  if (typeof value === 'boolean') {
    return value ? (
      <span className="px-2 py-1 text-xs bg-green-100 text-green-500 font-semibold">
        Etkin
      </span>
    ) : (
      <span className="px-2 py-1 text-xs bg-red-100 text-red-500 font-semibold">
        Devre Dışı
      </span>
    );
  }

  if (typeof value === 'number') {
    return value;
  }

  if (value && typeof value === 'object') {
    if (value.length > 0) {
      if (value[0]?.value) {
        return value[0].value;
      }
    }
  }

  if (!value) {
    return '-';
  }

  return value.name;
};

export const checkValue = (key, hiddens) => {
  const splitHiddenRows = hiddens.split(',');
  const getKey = typeof key === 'string' ? key : key.name;
  const findRow = splitHiddenRows.find(row => getKey === row.trim());
  if (findRow) {
    return false;
  }
  return true;
};

export const splitTableValues = (data, page, sortProperty) => {
  const splitNumber = page === 0 ? 0 : page * 10;

  const sortedList = data.sort((a, b) => {
    const firstItem = a[sortProperty].toLocaleLowerCase();
    const secondItem = b[sortProperty].toLocaleLowerCase();
    if (secondItem > firstItem) {
      return -1;
    }
    if (firstItem > secondItem) {
      return 1;
    }
    return 0;
  });
  const listItems = sortedList.slice(splitNumber, splitNumber + 10);

  return listItems;
};
