import {
  AddMenuCategoryTemplateModal,
  AddMenuProductTemplateModal,
  AppButton
} from 'components';
import React, { useEffect, useState } from 'react';
import api from 'services/api';
import { convertMenuTemplateData } from 'utils/menu';
import { uploadCareDeleteFile } from 'utils/uploadCare';

const Menu = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      await api.global
        .list('menuCategoryTemplate', 0)
        .then(async res => {
          const convertedCategoryData = convertMenuTemplateData(res.data);
          setCategoryData(convertedCategoryData);
        })
        .catch(error => console.log('error', error));

      await api.global
        .list('menuProductTemplate', 0)
        .then(async res => {
          const convertedProductData = convertMenuTemplateData(res.data);
          setProductData(convertedProductData);
        })
        .catch(error => console.log('error', error));

      setIsLoading(false);
    };
    getData();
  }, []);

  const deleteCategoryPhoto = async (id, photo) => {
    if (window.confirm('Silme işlemi geri alınamaz. Onaylıyor musunuz?')) {
      try {
        await api.global.delete('menu-category-template', id);
      } catch (err) {
        console.log(err);
      }
    }
    uploadCareDeleteFile(photo);
    location.reload();
  };

  const deleteProductPhoto = async (id, photo) => {
    if (window.confirm('Silme işlemi geri alınamaz. Onaylıyor musunuz?')) {
      try {
        await api.global.delete('menu-product-template', id);
      } catch (err) {
        console.log(err);
      }
    }
    uploadCareDeleteFile(photo);
    location.reload();
  };

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <main className="w-full flex flex-col">
          <div className="w-full bg-white rounded-md p-5 border shadow-md">
            <div className="w-full flex flex-row justify-between pb-2 border-b border-slate-100">
              <h3 className="text-slate-900 font-semibold text-lg">
                Kategoriler
              </h3>

              <AppButton
                onClick={() => setIsCategoryModalOpen(true)}
                isFullWidth={false}
              >
                Ekle
              </AppButton>
            </div>
            {categoryData.length > 0 ? (
              <ul>
                {categoryData.map((category, index) => (
                  <li key={index} className="mt-4">
                    <span className="text-slate-900 font-semibold">
                      {category.categoryName}
                    </span>

                    <div className="grid grid-cols-4 mt-4 gap-5">
                      {category.photos.map((item, i) => (
                        <div key={i}>
                          <img src={item.photo} />
                          <AppButton
                            onClick={() =>
                              deleteCategoryPhoto(item._id, item.photo)
                            }
                            className="!bg-red-100 !text-red-500 focus:!ring-red-500 hover:!bg-red-200 mt-4"
                          >
                            Sil
                          </AppButton>
                        </div>
                      ))}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <span className="mt-4 block text-gray-400 text-sm">
                Henüz bir şablon eklemediniz
              </span>
            )}
          </div>

          <div className="w-full bg-white rounded-md p-5 mt-20 border shadow-md">
            <div className="w-full flex flex-row justify-between pb-2 border-b border-slate-100">
              <h3 className="text-slate-900 font-semibold text-lg">Ürünler</h3>

              <AppButton
                onClick={() => setIsProductModalOpen(true)}
                isFullWidth={false}
              >
                Ekle
              </AppButton>
            </div>
            {productData.length > 0 ? (
              <ul>
                {productData.map((product, index) => (
                  <li key={index} className="mt-4">
                    <span className="text-slate-900 font-semibold">
                      {product.categoryName}
                    </span>
                    <div className="grid grid-cols-4 mt-4 gap-5">
                      {product.photos.map((item, i) => (
                        <div key={i}>
                          <img src={item.photo} />
                          <AppButton
                            onClick={() =>
                              deleteProductPhoto(item._id, item.photo)
                            }
                            className="!bg-red-100 !text-red-500 focus:!ring-red-500 hover:!bg-red-200 mt-4"
                          >
                            Sil
                          </AppButton>
                        </div>
                      ))}
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <span className="mt-4 block text-gray-400 text-sm">
                Henüz bir şablon eklemediniz
              </span>
            )}
          </div>

          <AddMenuCategoryTemplateModal
            onClose={() => setIsCategoryModalOpen(false)}
            isOpen={isCategoryModalOpen}
          />

          <AddMenuProductTemplateModal
            onClose={() => setIsProductModalOpen(false)}
            isOpen={isProductModalOpen}
          />
        </main>
      )}
    </>
  );
};

export default Menu;
