import { questionTypes } from 'constants/survey';

export const calculateQuestionRatePoint = (totalPoint, totalRate) => {
  if (totalRate === 0) {
    return '-';
  }
  totalPoint = (100 * totalPoint) / 5;

  return `%${Math.round(totalPoint / totalRate)}`;
};

export const changeQuestionType = surveyQuestions => {
  for (let i = 0; i < surveyQuestions.length; i++) {
    const question = surveyQuestions[i];
    question.questionType = questionTypes.find(
      type => type.value === question.questionType
    );
    if (question.multipleChoices.length === 0) {
      question.multipleChoices = [
        { choice: '', chooseCount: 0 },
        { choice: '', chooseCount: 0 }
      ];
    }
  }
  return surveyQuestions;
};
