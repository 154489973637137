export const categoryNames = [
  {
    label: 'Kahvaltılar',
    value: 'Kahvaltılar'
  },
  {
    label: 'Ana Yemekler',
    value: 'Ana Yemekler'
  },
  {
    label: 'Ara Yemekler',
    value: 'Ara Yemekler'
  },
  { label: 'Makarnalar', value: 'Makarnalar' },
  { label: 'Hamburgerler', value: 'Hamburgerler' },
  { label: 'Pizzalar', value: 'Pizzalar' },
  { label: 'Ara Sıcaklar', value: 'Ara Sıcaklar' },
  { label: 'Tatlılar', value: 'Tatlılar' },
  { label: 'Salatalar', value: 'Salatalar' },
  { label: 'Soğuk İçecekler', value: 'Soğuk İçecekler' },
  { label: 'Meşrubatlar', value: 'Meşrubatlar' },
  { label: 'Sıcak İçecekler', value: 'Sıcak İçecekler' },
  { label: 'Bitki Çayları', value: 'Bitki Çayları' },
  { label: 'Alkoller', value: 'Alkoller' },
  { label: 'Kahveler', value: 'Kahveler' }
];
