import {
  AddSurveyTemplateModal,
  AppButton,
  UpdateSurveyTemplateModal
} from 'components';
import React, { useEffect, useState } from 'react';
import api from 'services/api';

const Survey = () => {
  const [templates, setTemplates] = useState([]);
  const [updateModal, setUpdateModal] = useState({
    status: false,
    survey: null
  });
  const [isAddTemplateModalOpen, setIsAddTemplateModalOpen] = useState(false);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await api.global.list('surveyTemplate', page);

        setTemplates(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, []);

  const confirmDeleteTemplate = async id => {
    if (window.confirm('Silme işlemi geri alınamaz. Onaylıyor musunuz?')) {
      try {
        await api.global.delete('survey-template', id);
        location.reload();
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <main className="w-1/2 ml-auto mr-auto">
      <h1 className="text-center text-slate-900 font-medium text-xl">
        Anket Örnekleri
      </h1>
      <p className="mt-2 font-sm text-slate-600">
        Bu sayfada kullanıcılara sunulan anket örneklerini görebilirsiniz.
      </p>
      <div className="flex flex-row justify-between items-center">
        <span className="mt-4 text-lg inline-block font-medium text-slate-900">
          Anketler
        </span>
        <AppButton
          onClick={() => setIsAddTemplateModalOpen(true)}
          isFullWidth={false}
        >
          Anket Oluştur
        </AppButton>
      </div>
      <div className="grid grid-cols-1 bg-white  flex-col w-full mt-1 rounded-md border">
        {templates &&
          templates.length > 0 &&
          templates.map((survey, index) => (
            <div
              key={index}
              className={`py-6 px-4 flex items-center justify-between bg-white rounded-t-md border-b ${
                index + 1 === templates.length && 'border-b-0 rounded-b-md'
              }`}
            >
              <span className="text-slate-900 font-medium">
                {survey.surveyName}
              </span>
              <div className="flex flex-row items-center">
                <AppButton
                  className="mr-3 !bg-red-500 hover:!bg-red-300 focus:!ring-red-500"
                  onClick={() => confirmDeleteTemplate(survey._id)}
                  isFullWidth={false}
                >
                  Sil
                </AppButton>
                <AppButton
                  onClick={() => {
                    setUpdateModal({ status: true, survey: survey });
                  }}
                  isFullWidth={false}
                >
                  Güncelle
                </AppButton>
              </div>
            </div>
          ))}
      </div>

      {updateModal.status && (
        <UpdateSurveyTemplateModal
          survey={updateModal.survey}
          onClose={() => setUpdateModal({ status: false })}
          isOpen={updateModal.status}
        />
      )}
      {isAddTemplateModalOpen && (
        <AddSurveyTemplateModal
          onClose={() => setIsAddTemplateModalOpen(false)}
          isOpen={isAddTemplateModalOpen}
        />
      )}
    </main>
  );
};

export default Survey;
