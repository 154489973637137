// @ts-nocheck
import { apiConfig } from '../config';
import { authApi, globalApi } from 'services';
import { statisticApi } from './apis/statistic.api';

const api = apiConfig;

api.auth = authApi(api);
api.global = globalApi(api);
api.statistic = statisticApi(api);

export default api;
