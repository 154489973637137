import { AppTable } from 'components';
import React, { useEffect, useState } from 'react';
import api from 'services/api';

const CampaignList = () => {
  const titles = [
    'Kampanya Adı',
    'Gerekli Anket Sayısı',
    'Müşteri ID',
    'Aktiflik Durumu',
    'Oluşturma Tarihi'
  ];

  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      await api.global
        .list('campaigns', 0)
        .then(async res => {
          const resStores = res.data;

          setData(resStores);
        })
        .catch(error => console.log('error', error));
    };
    getData();
  }, []);

  return (
    <main className="w-full">
      <AppTable
        titles={titles}
        rows={data}
        type="campaigns"
        isDetail={true}
        hideRows="__v,_id,updatedAt,price,enabledStores,enabledMailAdresses"
      />
    </main>
  );
};

export default CampaignList;
