export const dateToString = (date, separator = '/') => {
  const formatterDate = new Date(date);
  let day = formatterDate.getDate();
  let month = formatterDate.getMonth() + 1;
  const year = formatterDate.getFullYear();

  return `${(day < 10 ? `0${day}` : day) + separator}${
    (month < 10 ? `0${month}` : month) + separator
  }${year}`;
};

export const timeToString = date => {
  const formatterDate = new Date(date);
  const hours = formatterDate.getHours().toString();
  let minutes = formatterDate.getMinutes().toString();
  minutes = minutes === '0' ? '00' : minutes;
  return `${hours}:${minutes}`;
};

export const dateToStringWithTime = (date, separator = '/') => {
  const formatterDate = new Date(date);
  let day = formatterDate.getDate();
  let month = formatterDate.getMonth() + 1;
  const year = formatterDate.getFullYear();
  const hour = formatterDate.getHours();
  const minutes = formatterDate.getMinutes();

  return `${(day < 10 ? `0${day}` : day) + separator}${
    (month < 10 ? `0${month}` : month) + separator
  }${year} - ${hour < 10 ? `0${hour}` : hour}:${
    minutes < 10 ? `0${minutes}` : minutes
  } `;
};
