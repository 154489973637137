const PREFIX = 'admin';

export const globalApi = api => ({
  list(type, page, sort) {
    return api.get(`/${PREFIX}/list/${type}/${page}/${sort}`);
  },
  get(key, id) {
    return api.get(`/${PREFIX}/${key}/get/${id}`);
  },
  update(key, id, params) {
    return api.put(`/${PREFIX}/${key}/update/${id}`, params);
  },
  delete(key, id) {
    return api.delete(`${PREFIX}/${key}/delete/${id}`);
  },
  post(key, params) {
    return api.post(`/${PREFIX}/${key}/add/`, params);
  }
});
