import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { checkValue, getValue } from 'utils/table';

const AppTable = ({
  titles,
  rows,
  isUpdate = false,
  isDelete = false,
  isDetail = false,
  onDelete,
  draggable = false,
  handleDragEnter,
  handleDragStart,
  draggIndex,
  draggingEnd,
  tableButtons,
  isSubstringActive = true,
  update,
  type,
  isSearchEnabled = false,
  hideRows = ''
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);

  // const handlePageChange = targetPage => {
  //   setSearchParams({ ...params, page: targetPage });
  // };

  const confirmDelete = id => {
    if (window.confirm('Silme işlemi geri alınamaz. Onaylıyor musunuz?')) {
      onDelete(id);
    }
  };

  const onSearchValueChange = value => {
    console.log(value);
  };

  return (
    <>
      <div
        className={`overflow-x-auto bg-white relative shadow-md sm:rounded-lg border ${
          draggIndex ? 'border-slate-200 border-2' : 'border-[#f1f1f4]'
        }`}
      >
        <div className={`flex flex-row w-full mt-2 px-6`}>
          {tableButtons && (
            <div className="flex flex-row right-6 py-2.5 w-full ">
              {tableButtons}
            </div>
          )}
        </div>
        {rows && rows.length > 0 ? (
          <table
            className={`w-full text-sm text-left table text-gray-800  ${
              (isSearchEnabled || tableButtons) && 'mt-2'
            }`}
          >
            <thead className="text-xs border-b border-dashed text-center">
              <tr>
                <th scope="col" className="py-3 px-6">
                  #
                </th>
                {titles.map((title, index) => (
                  <th
                    scope="col"
                    className="py-3 px-3 font-semibold"
                    key={`title${index}`}
                  >
                    {title.toLocaleUpperCase('tr')}
                  </th>
                ))}
                {isDetail && (
                  <th scope="col" className="py-3 px-3 font-semibold">
                    DETAY
                  </th>
                )}
                {isUpdate && (
                  <th scope="col" className="py-3 px-3 font-semibold">
                    DÜZENLE
                  </th>
                )}
                {isDelete && (
                  <th scope="col" className="py-3 px-3 font-semibold">
                    SİL
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr
                  className={`border-dashed   text-center ${
                    index + 1 !== rows.length && 'border-b'
                  } ${
                    draggable && draggIndex === index
                      ? 'bg-amber-100 '
                      : 'bg-white'
                  }`}
                  key={`tr${index}`}
                  draggable={draggable}
                  onDragStart={e => draggable && handleDragStart(e, index)}
                  onDragOver={e => {
                    draggable && e.preventDefault();
                  }}
                  onDrop={draggable ? draggingEnd : null}
                  onDragEnter={e => draggable && handleDragEnter(e, index)}
                >
                  <td className="px-3 py-4 text-gray-800" key={`index${index}`}>
                    {index + 1}
                  </td>
                  {Object.keys(row).map(
                    (key, index) =>
                      checkValue(key, hideRows) && (
                        <td
                          className="py-3 px-3 text-gray-500 max-w-96"
                          key={`row${index}`}
                        >
                          {getValue(
                            row[key],
                            key === 'logo',
                            isSubstringActive
                          )}
                        </td>
                      )
                  )}
                  {isDetail && (
                    <td className="py-3 px-3">
                      <Link
                        to={`/${type}/detail/${row._id}`}
                        className="py-2 text-xs flex items-center justify-center font-medium text-center text-white bg-orange-500 transition-all focus:ring-4 focus:outline-none focus:ring-orange-300 rounded-md hover:bg-orange-400 cursor-pointer"
                      >
                        Detay
                      </Link>
                    </td>
                  )}
                  {isUpdate && (
                    <td className="py-3 px-3">
                      <button
                        onClick={() => update(row._id)}
                        className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-500 rounded-md hover:bg-blue-400  focus:ring-4 focus:outline-none focus:ring-blue-300 cursor-pointer"
                      >
                        Düzenle
                      </button>
                    </td>
                  )}
                  {isDelete && (
                    <td className="py-3 px-3">
                      <button
                        onClick={() => confirmDelete(row._id)}
                        className="px-3 py-2 text-xs font-medium text-center text-white bg-red-500 rounded-md hover:bg-red-400  focus:ring-4 focus:outline-none focus:ring-red-300 cursor-pointer"
                      >
                        Sil
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center px-5 py-5 text-sm font-medium text-gray-500">
            Herhangi bir veri bulunamadı.
          </div>
        )}
      </div>
    </>
  );
};

export default AppTable;
