import React from 'react';
import { FormErrorMessage } from 'components';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import { Info } from '@mui/icons-material';

const AppSelect = ({
  label,
  options,
  name,
  error,
  rules,
  control,
  placeholder,
  isRequiredSymbolActive = false,
  isDisabled,
  infoText
}) => {
  return (
    <div className="w-full mb-5">
      <label
        htmlFor={name}
        className={`w-full block mb-2 text-sm font-medium ${
          error ? 'text-red-600' : 'text-slate-900'
        }`}
      >
        {label}
        {isRequiredSymbolActive && (
          <span className="align-top text-red-600 font-bold ml-1">*</span>
        )}
      </label>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field }) => (
          <Select
            {...field}
            options={options}
            isDisabled={isDisabled}
            captureMenuScroll={false}
            placeholder={placeholder}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused
                  ? 'rgb(55 65 81)'
                  : error
                    ? 'rgb(239 68 68)'
                    : 'rgb(229 231 235)',

                borderWidth: '1px',
                borderRadius: '8px',
                paddingTop: '2px',
                paddingBottom: '2px',
                lineHeight: '20px',
                boxShadow: '0px',
                color: 'rgb(15 23 42)',
                fontSize: '14px',
                '&:hover': {
                  borderColor: 'no',
                  cursor: 'pointer'
                }
              }),
              menuList: baseStyles => ({
                ...baseStyles,
                padding: '0px'
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: '14px',
                lineHeight: '20px',
                cursor: 'pointer'
              })
            }}
          />
        )}
      />
      {infoText && (
        <span className="text-gray-500 font-medium text-xs mt-2 flex items-center">
          <Info fontSize="small" className="mr-1 text-sm" />
          {infoText}
        </span>
      )}
      <FormErrorMessage label={label} error={error} />
    </div>
  );
};

export default AppSelect;
