export const convertMenuTemplateData = data => {
  const convertedTemplates = [];

  for (let i = 0; i < data.length; i++) {
    const template = data[i];

    const index = convertedTemplates.findIndex(
      item => item.categoryName === template.categoryName
    );

    if (index > -1) {
      convertedTemplates[index].photos.push({
        _id: template._id,
        photo: template.photo
      });
    } else {
      const templateDetail = {
        categoryName: template.categoryName,
        photos: [{ _id: template._id, photo: template.photo }]
      };
      convertedTemplates.push(templateDetail);
    }
  }

  convertedTemplates.sort((a, b) => {
    const firstItem = a.categoryName.toLocaleLowerCase('tr');
    const secondItem = b.categoryName.toLocaleLowerCase('tr');
    if (secondItem > firstItem) {
      return -1;
    }
    if (firstItem > secondItem) {
      return 1;
    }
    return 0;
  });

  return convertedTemplates;
};
