import React from 'react';
import { useAuth } from 'context/AuthContext';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AppInput, AppButton } from '../components';
import { t } from 'locales';
import { emailPattern } from 'constants/patterns';
import api from 'services/api';
import { RocketLaunchSharp } from '@mui/icons-material';

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = useAuth();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError
  } = useForm();

  const onSubmit = async submitData => {
    setIsLoading(true);
    await api.auth
      .login(submitData.email, submitData.password)
      .then(async response => {
        const user = response.data.admin;
        setUser(user);
        navigate('/');
      })
      .catch(error => {
        if (error.response.data.reason === 'email') {
          setError('email', {
            type: 'wrongData',
            message: error.response.data.message
          });
        }
        if (error.response.data.reason === 'password') {
          setError('password', {
            type: 'wrongData',
            message: error.response.data.message
          });
        }
      });
    setIsLoading(false);
  };

  return (
    <div>
      <div className="flex justify-center mb-4 bg-orange-400 px-4 py-4 rounded-xl">
        <RocketLaunchSharp fontSize={'large'} />
      </div>
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <AppInput
          label={t('_auth.email')}
          name="email"
          error={errors.email}
          rules={{ required: true, pattern: emailPattern }}
          register={register}
          placeholder={t('_auth.email')}
        />
        <AppInput
          label={t('_auth.password')}
          name="password"
          error={errors.password}
          rules={{ required: true }}
          type="password"
          register={register}
          placeholder={t('_auth.password')}
        />
        <AppButton type="submit" isLoading={isLoading}>
          {t('_auth.login')}
        </AppButton>
      </form>
    </div>
  );
};

export default Login;
