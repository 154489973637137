import React from 'react';
import { AppHeader, AppSidebar } from 'components';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import menu from 'constants/menu';
import { useAuth } from 'context/AuthContext';

const DashboardLayout = () => {
  const location = useLocation();
  const getUrl = location.pathname;
  const page = menu.find(item => {
    if (item.path === getUrl) {
      return true;
    } else if (item.childPaths) {
      return item.childPaths.find(child => child === getUrl);
    }
  });

  const PrivateRoute = ({ children }) => {
    const { user } = useAuth();

    if (!user) {
      return <Navigate to="/auth/login" replace={true} />;
    }

    return children;
  };
  return (
    <PrivateRoute>
      <div className="h-screen flex bg-gray-50">
        <div className="flex w-1/6 border-r border-neutral-300 px-8 py-10 bg-slate-950">
          <AppSidebar />
        </div>
        <div className="flex flex-col w-5/6 px-8 py-10 max-h-screen xl:overflow-y-scroll">
          <AppHeader pageName={page?.name} />
          <Outlet />
        </div>
      </div>
    </PrivateRoute>
  );
};

export default DashboardLayout;
